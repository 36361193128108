import React, {useState, useEffect} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import Link from 'gatsby-link';
import moment from "moment";
import axios from "axios";
import Loader from "../components/Loader";
import HtmlParser from "react-html-parser";


function Bloggrid() {
    const [posts, setPosts] = useState([]);
    const [offset, setOffset] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [count, setCount] = useState(0);

    const incrementCount = () => {
        setIsLoading(true);
        setOffset(offset + 12);
        setIsLoading(false);

    };

    const getPosts = async () => {
        setCount(0);
        const response = await axios.get(`https://www.tekrevol.com/blogs/wp-json/wp/v2/posts?_embed&offset=${offset}&per_page=12`);
        setCount(1);
        setPosts([...posts, ...response.data]);
        setIsLoaded(false);
    };
    useEffect(() => {
        getPosts();

    }, [offset]);

    return (

        <>
            {isLoaded ?
                <Loader/>
                :
                <div className="tekrevol_insights blogs_page p-100">
                    <Container className="h-100">
                        <Row className="h-100">
                            <Col xs={12} md={10} lg={10} xl={8} xxl={8}>
                                <div className="example" data-text="Publications">
                                    <h2>Insights</h2>
                                </div>
                            </Col>
                            <Col xs={12} md={12} lg={12} xl={12} xxl={12} className="tekrevol_insights_item">
                                <div className="blog_section">
                                    {posts.map((value, i) => {
                                        return (
                                            <div className="blog_list" key={i}>
                                                <div className=" blog_title">
                                                    <Link to={`/blog/${value.slug}`}>
                                                        <h3>{HtmlParser(value.title.rendered)}</h3>
                                                    </Link>
                                                </div>
                                                <div className=" dates">
                                                    {moment(value.date).format('MMMM D, YYYY')}
                                                </div>
                                            </div>
                                        );

                                    })}
                                </div>
                                {isLoaded ?
                                    <>
                                        {isLoading ? (
                                                <div className=" loading">
                                                    <img src=" https://www.tekrevol.com/Tek-Spinner.gif" alt="Loader"/>
                                                </div>
                                            ) :
                                            (
                                                <div className="btn_loadmore" onClick={incrementCount}>
                                                    Load More
                                                </div>
                                            )
                                        }
                                    </>
                                    : ''}
                            </Col>
                        </Row>
                    </Container>
                </div>
            }
        </>
    );
}

export default Bloggrid;

